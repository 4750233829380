import React from "react"
import tw, { styled } from "twin.macro"

const Date = styled.span`
    flex-basis: 20%;
    ${tw`text-sm text-gray-600`}
`

const Text = styled.p`
    flex-basis: 80%;
`

const News = () => {
  return (
    <section tw="py-16 bg-gray-100">
      <h2 tw="text-xl mb-8 text-center font-medium">ニュース</h2>
      <ul tw="px-4 md:max-w-screen-md m-auto list-none leading-8">
        <li tw="md:flex md:flex-wrap">
          <Date>2020年9月</Date>
          <Text>コーポレートサイトをリニューアルしました。</Text>
          <Date>2020年7月</Date>
          <Text>予約サービス「オテモト」をリリースしました。</Text>
          <Date>2019年5月</Date>
          <Text>弊社オフィスを千葉市花見川区幕張町から幕張ベイパークに引っ越ししました。</Text>
          <Date>2019年5月</Date>
          <Text>株式会社和聡を株式会社和奏に商号変更しました。</Text>
        </li>
      </ul>
    </section>
  )

}

export default News
