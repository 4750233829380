import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  library.add(faFacebook, faTwitter)

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          twitter
          facebook
        }
      }
    }
  `)

  const facebookUrl = `https://www.facebook.com/${site.siteMetadata.facebook}`
  const twitterUrl = `https://twitter.com/${site.siteMetadata.twitter}`

  return (
    <footer tw="mt-24">
      <div tw="text-gray-700">
        <StaticImage
          src="../assets/images/logo-square.png"
          tw="block w-40 mx-auto my-8"
          alt="株式会社和奏"
          placeholder="none"
        />
        <div tw="flex justify-center mb-6">
          <a href={facebookUrl} target="_blank" tw="mx-6">
            <FontAwesomeIcon icon={["fab", "facebook"]} tw="text-3xl" />
          </a>
          <a href={twitterUrl} target="_blank" tw="mx-6">
            <FontAwesomeIcon icon={["fab", "twitter"]} tw="text-3xl" />
          </a>
        </div>
        <p tw="text-center mb-4 text-sm">
          Copyright &copy; 2020 Wasoo Inc.
        </p>
      </div>
      <div tw="mt-4 py-4 text-center text-indigo-500 bg-mwl">
        Made with <span tw="text-heart text-xl">♥</span> in MAKUHARI
      </div>
    </footer>
  )
}

export default Footer
