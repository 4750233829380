import React from "react"
import { inject } from "@vercel/analytics"

import Footer from "../components/footer"
import { GlobalStyles } from "twin.macro"

// @ts-ignore
const Layout = ({ children }) => {
  inject()

  return (
    <div tw="flex flex-col justify-between min-h-screen">
      <GlobalStyles />
      <main tw="font-sans">
        { children }
      </main>
      <Footer />
    </div>
  )
}

export default Layout
