import React from "react"
import "twin.macro"
import { StaticImage } from "gatsby-plugin-image";

const Product = () => {
  return (
    <section tw="py-16 px-4 bg-gray-300">
      <h2 tw="text-xl mb-8 text-center font-medium">プロダクト</h2>
      <div tw="flex flex-row justify-center">
        <div tw="w-full md:w-1/2">
          <StaticImage
            src="../../assets/images/otemoto.jpg"
            tw="block w-2/3 mx-auto rounded-lg"
            alt="オテモト"
          />
          <p tw="mx-4 md:mx-8 my-8 text-gray-800">
            オテモトは、利用者も管理者もスマホ1つでいつでもどこでも操作できる予約サービスです。
            できることを簡単に、スマートに実現します。
          </p>
        </div>
      </div>
    </section>
  );
}

export default Product
