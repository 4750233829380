import React from "react"

const Business = () => (
  <section tw="py-16 bg-gray-200">
    <h2 tw="text-xl mb-8 text-center font-medium">事業概要</h2>
    <div tw="mx-auto px-8 max-w-screen-sm text-gray-800">
      <p tw="text-lg leading-8 text-center">
        私たちは<br />ソフトウェアの力を駆使し<br />地域の力となり<br />社会に貢献します。
      </p>
    </div>
    <div tw="mx-auto max-w-screen-md text-gray-800">
      <div tw="md:flex mt-16">
        <div tw="flex-1">
          <h3 tw="text-center mb-8">IT システム開発支援事業</h3>
          <p tw="px-4 md:px-8 leading-6">
            アーキテクチャ設計からチームビルディング、もちろん実装まで、現在の開発チームの状況に合わせて
            対応いたします。
          </p>
        </div>
        <div tw="mt-8 md:mt-0 flex-1">
          <h3 tw="text-center mb-8">Web サービス開発・運営</h3>
          <p tw="px-4 md:px-8 leading-6">
            これまで築き上げてきた開発スキルを、地域のために。地域をエンパワーするサービスを提供します。
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default Business
