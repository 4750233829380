import React from "react"

import Layout from "../layouts/default"
import SEO from "../components/seo"
import tw, { styled } from "twin.macro"

const NotFoundPage = () => {
  const H1 = styled.h1`
    font-size: 6rem;
    ${tw`text-center mt-16`}
  `

  return (
    <Layout>
      <SEO title="404: Not found" />
      <H1>404</H1>
    </Layout>
  )
}


export default NotFoundPage
