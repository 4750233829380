import React from "react"

import Layout from "../layouts/default"
import SEO from "../components/seo"
import FirstView from "../partials/index/first-view"
import Overview from "../partials/index/overview"
import Map from "../partials/index/map"
import Product from "../partials/index/product"
import Business from "../partials/index/business"
import News from "../partials/index/news"

const IndexPage = () => (
  <Layout>
    <SEO />
    <FirstView />
    <Overview />
    <Map />
    <Product />
    <Business />
    <News />
  </Layout>
)

export default IndexPage
