import React from "react"
import tw, { styled } from "twin.macro"

const Dl = tw.dl`md:flex md:flex-wrap`
const Dt = styled.dt`
    flex-basis: 20%;
    ${tw`text-sm md:text-base text-gray-600 my-2`}
`
const Dd = styled.dd`
    flex-basis: 70%;
    flex-grow: 1;
    ${tw`text-gray-800 my-2`}
`

const Overview = () => {
  return (
    <section tw="my-8 mx-4 md:flex md:justify-end">
      <div tw="md:w-1/2">
        <h2 tw="text-xl text-center md:text-left mb-4">会社概要</h2>
        <Dl>
          <Dt>会社名</Dt>
          <Dd>株式会社和奏(英語表記: Wasoo Inc.)</Dd>
          <Dt>設立</Dt>
          <Dd>2013年12月5日</Dd>
          <Dt>所在地</Dt>
          <Dd>〒261-0014<br/>千葉市美浜区若葉3-1-18 幕張ベイパーク クロスレジデンス S2-1</Dd>
          <Dt>資本金</Dt>
          <Dd>6,000,000円</Dd>
          <Dt>役員</Dt>
          <Dd>代表取締役 浦本 和則</Dd>
        </Dl>
      </div>
    </section>
  )
}

export default Overview
