import React, { useEffect, useRef, useState } from "react"
import "twin.macro"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

const Map = () => {
  const [map, setMap] = useState<mapboxgl.Map>()
  const mapContainer = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/misopeso/ckewvj9xb14lw19pjkcuks6cp",
        center: [140.050539, 35.646572],
        zoom: 11,
        interactive: false,
        logoPosition: "bottom-left",
        accessToken: process.env.GATSBY_MAPBOX_TOKEN
      })

      const marker = new mapboxgl.Marker()
        .setLngLat([140.050539, 35.646572])
        .addTo(map)

      map.on("load", () => {
        setMap(map);
        map.resize();
      });
    }

    if (!map) {
      initializeMap({ setMap, mapContainer })
    }
  }, [map])

  return (
    <div ref={el => (mapContainer.current = el)} tw="h-64 md:h-128" />
  )
}

export default Map
