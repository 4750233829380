import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import tw, { styled } from "twin.macro"

const Title = styled.h1`
    word-break: keep-all;
    ${tw`
      tracking-widest leading-tight font-bold text-5xl md:text-6xl text-gray-700
      w-68 md:w-auto pt-16 md:pt-24
    `}
`

const FirstView = () => {
  return (
    <div tw="grid">
      <div tw="row-start-1 row-end-auto col-start-1 col-end-auto">
        <StaticImage
          src="../../assets/images/mobile-first-view-background.jpg"
          layout="fullWidth"
          quality={80}
          tw="block md:hidden w-full h-screen overflow-hidden"
          alt="" />
        <StaticImage
          src="../../assets/images/first-view-background.jpg"
          layout="fullWidth"
          quality={80}
          tw="hidden md:block w-full h-screen lg:h-w80 overflow-hidden"
          alt="" />
      </div>
      <div tw="row-start-1 row-end-auto col-start-1 col-end-auto relative overflow-hidden">
        <div tw="flex flex-row-reverse md:justify-center">
          <Title>
            創造し、
            実現する。
          </Title>
        </div>
        <StaticImage
          src="../../assets/images/logo-eiji.png"
          tw="hidden md:inline-block w-1/2 opacity-50"
          style={{ position: 'absolute', left: '-80px', bottom: '98px' }}
          alt="株式会社和奏"
          placeholder="none"
        />
        <StaticImage
          src="../../assets/images/logo-kanji.png"
          tw="inline-block md:hidden w-2/3 opacity-50"
          style={{ position: 'absolute', right: '-72px', bottom: '-24px' }}
          alt="株式会社和奏"
          placeholder="none"
        />
      </div>
    </div>
  );
}

export default FirstView
